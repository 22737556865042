import { AbstractMesh, TransformNode, WebXRDefaultExperience, WebXRState } from "@babylonjs/core";
import { fromScene } from "../decorators";

/**
 * This represents a script that is attached to a node in the editor.
 * Available nodes are:
 *      - Meshes
 *      - Lights
 *      - Cameas
 *      - Transform nodes
 * 
 * You can extend the desired class according to the node type.
 * Example:
 *      export default class MyMesh extends Mesh {
 *          public onUpdate(): void {
 *              this.rotation.y += 0.04;
 *          }
 *      }
 * The function "onInitialize" is called immediately after the constructor is called.
 * The functions "onStart" and "onUpdate" are called automatically.
 */
export default class VRPlayer extends TransformNode {
    private _xr: WebXRDefaultExperience;

    @fromScene("Floors")
    private _floorMeshes: AbstractMesh;

    /**
     * Override constructor.
     * @warn do not fill.
     */
    // @ts-ignore ignoring the super call as we don't want to re-init
    protected constructor() { }

    /**
     * Called on the node is being initialized.
     * This function is called immediatly after the constructor has been called.
     */
    public onInitialize(): void {
        // ...
    }

    /**
     * Called on the scene starts.
     */
    public onStart(): void {
        this._scene.getEngine().setHardwareScalingLevel(2);

        this._scene.createDefaultXRExperienceAsync({
                floorMeshes: [this._floorMeshes],
            })
            .then(xr => {
                this._xr = xr;

                this._xr.baseExperience.onInitialXRPoseSetObservable.addOnce(camera => {
                    camera.position.x = this.position.x;
                    camera.position.y = this.position.y;
                    camera.position.z = this.position.z;

                    camera.minZ = 1;
                });

                this._xr.baseExperience.onStateChangedObservable.add(state => {
                    switch (state) {
                        case WebXRState.ENTERING_XR:
                        case WebXRState.IN_XR:
                            this._scene.getEngine().setHardwareScalingLevel(1);
                            break;

                        default:
                            this._scene.getEngine().setHardwareScalingLevel(4);
                            break;
                    }
                })
            }, console.error);
    }

    /**
     * Called each frame.
     */
    public onUpdate(): void {
        // ...
    }

    /**
     * Called on a message has been received and sent from a graph.
     * @param message defines the name of the message sent from the graph.
     * @param data defines the data sent in the message.
     * @param sender defines the reference to the graph class that sent the message.
     */
    public onMessage(name: string, data: any, sender: any): void {
        switch (name) {
            case "myMessage":
                // Do something...
                break;
        }
    }
}
